/**************************************

component.scss

***************************************/
/* ---------------------------------------------------------
テキスト装飾
----------------------------------------------------------*/
/*-- 見出し --*/

/*-- リード文--*/
