/**************************************

mixins.scss

***************************************/
/* ---------------------------------------------------------
media queries
----------------------------------------------------------*/
@mixin media($breakPoint){
	@media screen and (max-width: $breakPoint) {
		@content;
	}
}


/* ---------------------------------------------------------
clearfix
----------------------------------------------------------*/
@mixin clearfix(){
    &::before,
    &::after{
        content: '';
        display: table;
    }
    &::after{
        clear: both;
    }
}

/* ---------------------------------------------------------
font-size
----------------------------------------------------------*/
@mixin fontsize($size: 16, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) * 1rem;
}

/* ---------------------------------------------------------
opacity
----------------------------------------------------------*/
@mixin opa(){
    opacity: 0.8;
    transition: opacity 0.8s;
}

/* ---------------------------------------------------------
border-radius
----------------------------------------------------------*/
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

/* ---------------------------------------------------------
transform
----------------------------------------------------------*/
/*-- translate--*/
@mixin translate($translate-x,$translate-y) {
	-webkit-transform: translate($translate-x,$translate-y);
  -moz-transform: translate($translate-x,$translate-y);
	-ms-transform: translate($translate-x,$translate-y);
	transform: translate($translate-x,$translate-y);
}

/*-- translateY--*/
@mixin translateY($translateY) {
	-webkit-transform: translateY($translateY);
  -moz-transform: translateY($translateY);
	-ms-transform: translateY($translateY);
	transform: translateY($translateY);
}

/*-- translateX--*/
@mixin translateX($translateX) {
  -webkit-transform: translateX($translateX);
  -moz-transform: translateX($translateX);
  -ms-transform: translateX($translateX);
  transform: translateX($translateX);
}

/* ---------------------------------------------------------
backface-visibility
----------------------------------------------------------*/
@mixin backface-visibility($bv) {
	-webkit-backface-visibility: ($bv);
  backface-visibility: ($bv);
}

/* ---------------------------------------------------------
flexbox
----------------------------------------------------------*/

@mixin flex() {
		display: flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: -webkit-box;/*--- Androidブラウザ用 ---*/
		display: -ms-flexbox;/*--- IE10 ---*/
		display: -webkit-flex;/*--- safari（PC）用 ---*/
}

@mixin justify() {
　-webkit-box-pack: justify;  /*--- Androidブラウザ用 ---*/
	-ms-flex-pack: justify;  /*--- IE10 ---*/
	-webkit-justify-content: space-between;  /*--- safari（PC）用 ---*/
	justify-content: space-between;
}


@mixin flex-start() {
	-webkit-box-pack: flex-start;  /*--- Androidブラウザ用 ---*/
		-ms-flex-pack: flex-start;  /*--- IE10 ---*/
		-webkit-justify-content: flex-start;  /*--- safari（PC）用 ---*/
		justify-content: flex-start;
}

@mixin content() {
-webkit-box-pack: center;
     -ms-flex-pack: center;
   justify-content: center;
}

@mixin wrap() {
	-ms-flex-wrap:wrap;/*--- IE10用 ---*/
	-webkit-flex-wrap:wrap;/*--- safari（PC）用 ---*/
	flex-wrap:wrap;
}

@mixin column() {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		 -ms-flex-direction: column;
				 flex-direction: column;
}

@mixin column-reverse() {
	-ms-flex-direction: column-reverse; /*IE10*/
	-webkit-box-direction: reverse; /* Android4.3以下、Safari3.1〜6.0 */
	-webkit-flex-direction: column-reverse; /* Safari6.1以降 */
	flex-direction: column-reverse;
}

@mixin flex-direction() {
	flex-direction: row;
	-ms-flex-direction:row-reverse; /*IE10*/
	-webkit-flex-direction:row-reverse; /*old webkit browser*/
}
