/**************************************

footer.scss

***************************************/
.footer{
    // footer__nav
	.footer__nav {
        width: wCon(1145,1250);
        max-width: 1145px;
        margin: 0 auto;
        border-top: 1px solid #ACADAE;
        box-sizing: border-box;
        padding-top: 50px;
        @include media($notepcSize){
            width: 100%;
        }
        .footer__addressInner {
            @include flex();
            @include justify();
            width: wCon(1000,1145);
            max-width: 1000px;
            margin: 0 auto 60px;
            align-items: center;
            @include media($pcSize){
                width: wCon(1200,1280);
                max-width: 1200px;
            }
            @include media($notepcSize){
                @include column();
            }
            .footer__addressLogo {
                width: wCon(400,1000);
                max-width: 400px;
                @include media($notepcSize){
                    width: 100%;
                    max-width: 490px;
                    margin-bottom: 45px;
                }
                @include media($mobileSize){
                    max-width: 553px;
                }
                @include media($mobileSize_s){
                    margin-bottom: 25px;
                }
            }
            .footer__addressBox {
                width: wCon(485,1000);
                max-width: 485px;
                @include media($notepcSize){
                    width: 100%;
                }
                @include media($mobileSize){
                    max-width: 553px;
                }
                .footer__addressTxt {
                    line-height: 1.4;
                    @include fontsize(18);
                    font-weight: bold;
                    @include media($mobileSize){
                        @include fontsize(16);
                    }
                }
            }
        }
        .footer__list {
            width: wCon(1000,1250);
            max-width: 1000px;
            // margin: 0 auto 90px;
            margin-bottom: 90px;
            padding-left: 8%;
            box-sizing: border-box;
            @include flex();
            @include justify();
            @include media($notepcSize){
                padding-left: 0;
                margin: 0 auto 90px;
            }
            @include media($mobileSize){
                @include wrap();
                width: wCon(600,640);
                max-width: 600px;
            }
            .footer__listInner {
                @include media($mobileSize){
                    width: wCon(300,600);
                    &:nth-of-type(1) {
                        margin-bottom: 35px;
                    }
                    &:nth-of-type(2) {
                        margin-bottom: 35px;
                    }
                }
                @include media($mobileSize_s){
                    width: wCon(300,600);
                }
                .footer__listTit {
                    margin-bottom: 20px;
                    @include media($mobileSize){
                        margin-bottom: 10px;
                    }
                    a {
                        @include fontsize(20);
                        color: #686767;
                        @include media($notepcSize){
                            @include fontsize(16);
                        }
                        @include media($mobileSize){
                            @include fontsize(18);
                        }
                        @include media($mobileSize_s){
                            @include fontsize(14);
                        }
                    }
                }
                .footer__listDetail {
                    line-height: 1.5;
                    a {
                        @include fontsize(15);
                        color: #A8A9A9;
                        @include media($notepcSize){
                            @include fontsize(12);
                        }
                        @include media($mobileSize){
                            @include fontsize(13);
                        }
                    }
                }
            }
        }
    }
    // footer__copy
    .footer__copy {
        background-color: #FFDE00;
        color: #000;
        .footer__copyTxt {
            padding: 13px 0;
            text-align: center;
            box-sizing: border-box;
            @include fontsize(20);
            @include media($mobileSize){
                @include fontsize(16);
            }
        }
    }
}
/* ---------------------------------------------------------

----------------------------------------------------------*/

/*--  --*/