/**************************************

header.scss

***************************************/
.header{
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    position: fixed;
    z-index: 50;
    border-bottom: 1px solid #ACADAE;
    @include media($notepcSize){
        border-bottom: none;
    }
    img {
        width: 100%;
    }
    // pc時
	.nav__pc {
        @include flex();
        @include justify();
        width: wCon(1180,1250);
        max-width: 1180px;
        margin: 0 auto;
        padding-bottom: 15px;
        box-sizing: border-box;
        align-items: center;
        @include media($pcSize){
            width: wCon(1260,1280);
            max-width: 1260px;
        }
        @include media($notepcSize){
            display: none;
        }
        .nav__logo {
            width: wCon(230,1180);
            margin-top: 10px;
        }
        .nav__teams {
            @include flex();
            @include justify();
            width: wCon(620,1180);
            max-width: 620px;
            @include media($pcSize){
                width: wCon(640,1180);
                max-width: 640px;
            }
            .nav__team {
                a {
                    @include fontsize(13);
                    font-weight: 600;
                    transition: .8s;
                    @include media($pcSize){
                        @include fontsize(12);
                    }
                }
                &:nth-of-type(1) {
                    a {
                        color: #EE7300;
                    }
                }
                &:nth-of-type(2) {
                    a {
                        color: #399B64;
                    }
                }
                &:nth-of-type(3) {
                    a {
                        color: #C96391;
                    }
                }
                &:nth-of-type(4) {
                    a {
                        color: #0AA0CC;
                    }
                }
            }
        }
        .nav__myPage {
            width: wCon(250,1180);
        }
    }
    // sp時
    .nav__sp {
        display: none;
        @include media($notepcSize){
            @include flex();
            @include justify();
            // width: wCon(1070,1080);
            // max-width: 1070px;
            // margin: 0 auto;
            width: 100%;
            box-sizing: border-box;
            padding: 15px 15px;
            box-sizing: border-box;
            align-items: center;
            position: relative;
            background-color: #fff;
            border-bottom: 1px solid #ACADAE;
            z-index: 40;
            .nav__logo {
                width: 275px;
                margin-top: 10px;
                @include media($mobileSize_s){
                    width: 175px;
                }
            }
            .nav__hamburger {
                &::after {
                    transition: .5s;
                    content: '';
                    display: block;
                    background-image: url(../img/common/hamburger_open.svg);
                    background-repeat: no-repeat;
                    width: 73px;
                    height: 60px;
                    @include media($mobileSize_s){
                        background-image: url(../img/common/hamburger_open_sp.svg);
                        width: 48px;
                        height: 38px;
                    }
                }
            }
            .nav__hamburger--active {
                &::after {
                    transition: .5s;
                    content: '';
                    display: block;
                    background-image: url(../img/common/hamburger_close.svg);
                    background-repeat: no-repeat;
                    width: 73px;
                    height: 60px;
                    @include media($mobileSize_s){
                        background-image: url(../img/common/hamburger_close_sp.svg);
                        width: 48px;
                        height: 38px;
                    }
                }
            }
        }
    }
    .nav__menu {
        position: fixed;
        z-index: 20;
        top: -25%;
        right: -100%;
        transition: .8s;
        overflow-y: scroll;
        transform: translateZ(0);
        width: wCon(910,1080);
        max-width: 910px;
        height: 100%;
        margin-left: auto;
        border-left: 1px solid #ACADAE;
        border-bottom: 1px solid #ACADAE;
        background-color: #fff;
        z-index: 30;
        .nav__menuInner {
            box-sizing: border-box;
            padding: 370px 40px 55px 63px;
            @include media($mobileSize){
                padding-top: 315px;
                padding-left: 43px;
            }
                @include media($mobileSize_s){
                padding-top: 300px;
            }

            .nav__lists {
                border-bottom: 1px solid  #ACADAE;
                padding-bottom: 10px;
                margin-bottom: 10px;
                &:last-of-type {
                    border-bottom: 0;
                    margin-bottom: 50px;
                    padding-bottom: 0;
                }
                &:nth-of-type(1) {
                .nav__listTit {
                        a {
                            color: #EE7300;
                        }
                    }
                }
                &:nth-of-type(2) {
                .nav__listTit {
                        a {
                            color: #399B64;
                        }
                    }
                }
                &:nth-of-type(3) {
                .nav__listTit {
                        a {
                            color: #C96391;
                        }
                    }
                }
                &:nth-of-type(4) {
                .nav__listTit {
                        a {
                            color: #0AA0CC;
                        }
                    }
                } 
                .nav__listTit {
                    a {
                        line-height: 3;
                        @include fontsize(18);
                        transition: .8s;
                        &:hover {
                            @include opa();
                        }
                    }
                }
                .nav__listDetail {
                    a {
                        line-height: 2;
                        @include fontsize(16);
                    }
                }
            }
            .nav__contact {
                background-color: #FFF100;
                border-radius: 10px;
                transition: .8s;
                &:hover {
                    background-color: #E60012;
                }
                a {
                    width: 100%;
                    display: block;
                    padding: 20px 0;
                    box-sizing: border-box;
                    font-family: tbudrgothic-std, sans-serif;
                    font-style: normal;
                    font-weight: 200;
                    color: #E60012;
                    text-align: center;
                    @include fontsize(32);
                    transition: .8s;
                    @include media($mobileSize){
                        @include fontsize(22);
                    }
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
    .nav__menu--active {
        top: -25%;
        right: 0;
        transition: .5s;
    }
    .nav__myPage--sp {
        width: 33%;
        position: fixed;
        z-index: -2;
        right: 2%;
        @include media($mobileSize_s){
            right: 4%;
            width: 38%;
        }
    }
}

/* ---------------------------------------------------------

----------------------------------------------------------*/
/*--  --*/
