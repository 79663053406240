/**************************************

top.scss

***************************************/
.top {
    position: relative;
    padding-top: 70px;
    @include media($notepcSize){
        padding-top: 94px;
    }
    @include media($mobileSize){
        padding-top: 75px;
    }
    img {
        width: 100%;
    }
    //mainVisual
    .mainVisual {
        position: relative;
        .mainVisual__bg {
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 8;
        }
        .mainVisual__txt {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 5%;
            z-index: 10;
            width: wCon(1080,1250);
            max-width: 1080px;
            margin: 0 auto;
            @include media($mobileSize){
                top: 10%;
            }
            .mainVisual__txtInner {
                @include flex();
                @include justify();
                @include media($notepcSize){
                    margin-bottom: 40px;
                }
                @include media($mobileSize){
                    margin-bottom: 20px;
                }
            }
            .mainVisual__tits {
                .mainVisual__logo {
                    width: 570px;
                    margin-bottom: 50px;
                    @include media($notepcSize){
                        width: 400px;
                    }
                    @include media($mobileSize){
                        width: 68%;
                    }
                }
                .mainVisual__logoSub {
                    width: 380px;
                    @include media($notepcSize){
                        width: 240px;
                    }
                    @include media($mobileSize){
                        width: 55%;
                    }
                }
            }
            .mainVisual__copys {
                width: 153px;
            }
            .mainVisual__menu {
                width: wCon(1200,1250);
                max-width: 1200px;
                @include flex();
                @include justify();
                margin: 0 auto;
                @include media($notepcSize){
                    width: wCon(480,960);
                    max-width: 960px;
                    margin-left: 0;
                }
                @include media($mobileSize){
                    width: wCon(520,640);
                    margin: 0 auto;
                }
                .mainVisual__thumbnails {
                    @include flex();
                    @include justify();
                    @include media($notepcSize){
                        @include wrap();
                    }
                    .mainVisual__thumbnail {
                        &:nth-of-type(1) {
                            width: 257px;
                            // position: absolute;
                            // left: 0;
                            // bottom: 80%;
                            // // top: 0;
                            margin: 150px auto 0;
                            @include media($pcSize){
                                width: 220px;
                            }
                            @include media($notepcSize){
                                margin: 0 0 30px 0;
                                width: wCon(270,600);
                            }
                            @include media($mobileSize){
                                margin: 0 auto 20px;
                            }
                        }
                        &:nth-of-type(2) {
                            width: 200px;
                            // position: absolute;
                            // left: 25%;
                            // // right: 0;
                            // bottom: 100%;
                            // // top: 0;
                            margin: -20px auto 0;
                            @include media($pcSize){
                                width: 180px;
                            }
                            @include media($notepcSize){
                                margin: 0 0 30px 0;
                                width: wCon(270,600);
                            }
                            @include media($mobileSize){
                                margin: 0 auto 20px;
                            }
                        }
                        &:nth-of-type(3) {
                            width: 250px;
                            // position: absolute;
                            // left: 33%;
                            // // right: 0;
                            // bottom: -12%;
                            // // top: 0;
                            margin: 200px 0 0 -70px;
                            @include media($pcSize){
                                width: 200px;
                            }
                            @include media($notepcSize){
                                margin: 0;
                                width: wCon(270,600);
                            }
                        }
                        &:nth-of-type(4) {
                            width: 180px;
                            // position: absolute;
                            // left: 40%;
                            // right: 0;
                            // bottom: 70%;
                            // // top: 0;
                            margin: 170px auto 0;
                            @include media($pcSize){
                                width: 140px;
                            }
                            @include media($notepcSize){
                                margin: 0;
                                width: wCon(270,600);
                            }
                        }
                        a {
                            width: 100%;
                            display: block;
                            img {
                                transition: .8s;
                            }
                        }
                    }
                }
                .mainVisual__contact {
                    width: 203px;
                    margin: 380px auto 0;
                    @include media($pcSize){
                        width: 140px;
                        margin: 110px auto 0;
                    }
                }
            }
        }
        .mainVisual__sliders {
            .mainVisual__slider {
            }
        }
    }
    // contact--sp
    .contactSp {
        position: relative;
        z-index: 10;
        .contactSp__contact--sp {
            text-align: center;
            background-color: #FFF100;
            box-sizing: border-box;
            padding: 25px 0;
            width: wCon(938,960);
            max-width: 960px;
            margin: 190px auto 0;
            @include media($mobileSize){
                margin: 70% auto 0;
            }
            a {
                width: 100%;
                display: block;
                font-weight: bold;
                @include fontsize(51);
                color: #E60012;
                @include media($mobileSize){
                    @include fontsize(30);
                }
            }
        }
    }
    // news
    .news {
        position: relative;
        margin-top: 100px;
        width: 100%;
        background-color: #ED8000;
        box-sizing: border-box;
        @include media($pcSize){
            margin-top: 200px;
        }
        @include media($notepcSize){
            margin-top: 70px;
        }
        // &::before {
        //     background-image: url(../img/top/top_newsBg_pic3.svg);
        //     background-size: 100% auto;
        //     background-repeat: no-repeat;
        //     position: absolute;
        //     content: '';
        //     left: 0;
        //     right: 0;
        //     top: -8%;
        //     height: 190px;
        // }
        // &::after {
        //     background-image: url(../img/top/top_newsBg_pic2.svg);
        //     background-size: 100% auto;
        //     background-repeat: no-repeat;
        //     position: absolute;
        //     content: '';
        //     left: 0;
        //     right: 0;
        //     bottom: -5%;
        //     height: 180px;
        // }
        .news__visualBgTop {
            img {
                margin-top: -3px;
            }
        }
        .news__visualBgBottom {
            img {
                margin-bottom: -3px;
            }
        }
        .news__outer {
            @include media($notepcSize){
                margin-bottom: 20px;
            }
            .news__inner {
                @include flex();
                @include justify();
                width: wCon(1046,1250);
                max-width: 1046px;
                margin: 0 auto;
                @include media($pcSize){
                    width: wCon(1200,1280);
                    max-width: 1200px;
                }
                @include media($notepcSize){
                    width: wCon(840,960);
                    max-width: 840px;
                    @include column();
                }
                .news__tit {
                    box-sizing: border-box;
                    border-right: 1px solid #fff;
                    padding-right: 55px;
                    padding-top: 50px;
                    @include media($notepcSize){
                        padding-top: 10px;
                        border-bottom: 1px solid #fff;
                        border-right: 0;
                        text-align: center;
                        padding-right: 0;
                        padding-bottom: 20px;
                        margin-bottom: 30px;
                    }
                    .news__titEn {
                        @include fontsize(47);
                        font-family: kozuka-gothic-pr6n, sans-serif;
                        font-weight: bold;
                        font-style: normal;
                        color: #fff;
                        margin-bottom: 17px;
                    }
                    .news__titJa {
                        @include fontsize(21);
                        font-family: kozuka-gothic-pr6n, sans-serif;
                        font-weight: bold;
                        font-style: normal;
                        color: #fff;
                    }
                }
                .news__new {
                    @include flex();
                    @include wrap();
                    width: wCon(807,1046);
                    max-width: 807px;
                    margin-bottom: 25px;
                    @include media($notepcSize){
                        width: 100%;
                        box-sizing: border-box;
                        border-bottom: 1px solid #fff;
                    }
                    .news__contents {
                        width: wCon(370,807);
                        margin-right: wCon(60,807);
                        margin-bottom: 35px;
                        @include media($mobileSize){
                            width: 100%;
                            margin-right: 0;
                        }
                        &:nth-of-type(even) {
                            margin-right: 0;
                        }
                        .news__detail {
                            @include flex();
                            @include justify();
                            .news__thumbnail {
                                width: wCon(100,370);
                                img {
                                    border-radius: 5px;
                                    transition: .8s;
                                    &:hover {
                                        @include opa();
                                    }
                                }
                            }
                            .news__txt {
                                width: wCon(260,370);
                                transition: .8s;
                                margin-top: 5px;
                                &:hover {
                                    @include opa();
                                }
                                .news__ymd {
                                    border: 1px solid #fff;
                                    box-sizing: border-box;
                                    font-family: kozuka-gothic-pr6n, sans-serif;
                                    font-weight: 500;
                                    @include fontsize(14);
                                    color: #fff;
                                    padding: 5px;
                                }
                                .news__copy {
                                    color: #fff;
                                    @include fontsize(14);
                                    font-family: kozuka-gothic-pr6n, sans-serif;
                                    font-weight: 500;
                                    margin-top: 13px;
                                    line-height: 1.5;
                                }
                            }
                        }
                    }
                }
            }
            .news__btn {
                width: 200px;
                margin: 0 auto;
                background-color: #fff;
                border-radius: 5px;
                box-sizing: border-box;
                padding: 13px 0;
                a {
                    text-align: center;
                    width: 100%;
                    display: block;
                    color: #EE8100;
                    font-family: kozuka-gothic-pr6n, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    @include fontsize(20);
                }
            }
        }
    }
    // message 
    .message {
        position: relative;
        z-index: 3;
        .message__inner {
            @include flex();
            @include justify();
            width: wCon(1100,1250);
            max-width: 1100px;
            margin: 0 auto;
            @include media($notepcSize){
                @include column();
            }
            .message__copy {
                width: wCon(470,1100);
                color: #7D7D7D;
                @include fontsize(26);
                line-height: 3;
                @include media($pcSize){
                    line-height: 2;
                    @include fontsize(22);
                }
                @include media($notepcSize){
                    width: 100%;
                }
                @include media($mobileSize){
                    @include fontsize(18);
                }
                @include media($mobileSize_s){
                    @include fontsize(16);
                }
            }
            .message__logo {
                width: wCon(595,1100);
                @include media($notepcSize){
                    margin-left: auto;
                }
                @include media($mobileSize){
                    width: wCon(320,640);
                }
            }
        }
    }
    .contents {
        position: relative;
        margin-top: -80px;
        margin-bottom: 320px;
        background-color: #8CC11F;
        border-top-left-radius: 1800px 220px;
        border-top-right-radius: 1000px 10px;
        border-bottom-right-radius: 1800px 260px;
        border-bottom-left-radius: 1000px 10px;
        @include media($mobileSize){
            margin-top: -50px;
            margin-bottom: 160px;
        }
        .contents__inner {
            @include flex();
            @include justify();
            @include flex-direction();
            width: wCon(1000,1250);
            max-width: 1000px;
            margin: 0 auto;
            padding: 150px 0;
            box-sizing: border-box;
            @include media($notepcSize){
                @include column();
                padding: 90px 0;
                width: wCon(900,960);
            }            
            .contents__teams {
                width: wCon(650,1000);
                @include media($notepcSize){
                    width: 100%;
                }
                .contents__team {
                    margin-bottom: 40px;
                    @include media($notepcSize){
                        margin-bottom: 25px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        .contents__teamImg {
                            position: relative;
                            img {
                                border-radius: 10px;
                            }
                        }
                        .contents__nameOuter {
                            width: wCon(470,650);
                            margin: 0 auto 0 5%;
                            text-align: center;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            @include media($notepcSize){
                                width: wCon(500,960);
                            }
                            @include media($mobileSize){
                                width: wCon(200,375);
                            }
                            .contents__nameInner {
                                position: relative;
                                background-color: #FFDE00;
                                box-sizing: border-box;
                                padding: 15px 0;
                                border-bottom-right-radius: 8px;
                                border-bottom-left-radius: 8px;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 0;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    z-index: -1;
                                    transition: .4s;
                                    background-color: #ED8000;
                                    border-bottom-right-radius: 8px;
                                    border-bottom-left-radius: 8px;
                                    display: block;
                                }
                                span {
                                    position: relative;
                                    z-index: 2;
                                    @include fontsize(35);
                                    color: #5A4F4A;
                                    font-weight: bold;
                                    transition: .8s;
                                    @include media($pcSize){
                                        @include fontsize(30);
                                    }
                                    @include media($notepcSize){
                                        @include fontsize(20);
                                    }
                                    @include media($mobileSize){
                                        @include fontsize(18);
                                    }
                                    @include media($mobileSize_s){
                                        @include fontsize(14);
                                    }
                                }
                            }
                        }
                        &:hover {
                        .contents__teamImg {
                                img {
                                    @include opa();
                                }
                            }
                            .contents__nameOuter {
                                .contents__nameInner {
                                    &::before {
                                        background-color: #ED8000;
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        top: 0;
                                        transition: .4s;
                                        z-index: 1;
                                    }
                                    span {
                                        color: #fff;
                                        transition: .8s;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .contents__tit {
                width: wCon(233,1000);
                text-align: right;
                @include media($notepcSize){
                    width: 100%;
                    text-align: center;
                }
                .contents__titInner {
                    @include media($notepcSize){
                        border-bottom: 1px solid #fff;
                        box-sizing: border-box;
                        width: wCon(840,960);
                        margin: 0 auto 20px;
                        padding-bottom: 20px;
                    }
                }
                .contents__titEn {
                    @include fontsize(47);
                    font-family: kozuka-gothic-pr6n, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    color: #fff;
                    margin-bottom: 17px;
                }
                .contents__titJa {
                    @include fontsize(21);
                    font-family: kozuka-gothic-pr6n, sans-serif;
                    font-weight: bold;
                    font-style: normal;
                    color: #fff;
                }
                .contents__copy {
                    -webkit-writing-mode: vertical-rl;
                    -ms-writing-mode: tb-rl;
                    writing-mode: vertical-rl;
                    line-height: 2;
                    @include fontsize(24);
                    font-weight: bold;
                    margin: 0 auto;
                    color: #fff;
                    height: 550px;
                    @include media($notepcSize){
                        height: auto;
                        @include column();
                        -webkit-writing-mode: horizontal-tb;
                        -ms-writing-mode: horizontal-tb;
                        writing-mode: horizontal-tb;
                        @include fontsize(18);
                        margin-bottom: 40px;
                    }    
                    @include media($mobileSize){
                        @include fontsize(16);
                    }
                }
            }
        }
        .contents__logo {
            position: relative;
            width: wCon(1000,1250);
            max-width: 1000px;
            margin: 0 auto;
            .contents__logoInner {
                width: 160px;
                position: absolute;
                right: 0;
                top: -108px;
                @include media($notepcSize){
                    width: 95px;
                    top: -77px;
                }
            }
        }
    }
}



/*--  --*/
