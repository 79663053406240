/**************************************

common.scss

***************************************/
/* ---------------------------------------------------------
大枠のレイアウト
----------------------------------------------------------*/
/*--  --*/
#mainContainer{
	color: $fontColorMain;
}

.container{
	width: 960px;
	margin: 0 auto;
	position: relative;
	@include media($notepcSize){
		width: 87.5%;
	}
	@include media($mobileSize){
		width: 87.5%;
	}
}

a {
	transition: .8s;
	&:hover {
		@include opa();
	}
}