/**************************************

base.scss

***************************************/
/* ---------------------------------------------------------
初期設定
----------------------------------------------------------*/
html {
	color: $fontColorMain;
	background-color: $bgColorMain;
	font-size: 16px;
	line-height: 1.6;
	@include media($mobileSize){
		font-size: 14px;
	}
}
body {
	font-family: $fontFamilyBase;
	font-style: normal;
	font-weight: 300;
	height: 100%;
}
a{
	text-decoration: none;
	color: $fontColorMain;
}
img{
	max-width: 100%;
	height: auto;
}
